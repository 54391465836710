/*  Bootstrap Clearfix auto-clear */
.app-news5-admin-menu {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  .toolbar {
      display:flex;
      ul {
        padding-left: 10px;

        &:first-child {
          padding-left: 0;
        }
      }
  }
  span {
      line-height:28px;
      font-size:14px;
      color:#666;
  }
  .sc-menu {
      li {
          display:inline-block;
          a{
              div{

                    background:#666;
              }
          }
      }
  }
}
@mixin row-first-child($col-type) {
  .col-#{$col-type}- {
    &1:nth-child(12n + 1),
    &2:nth-child(6n + 1),
    &3:nth-child(4n + 1),
    &4:nth-child(3n + 1),
    &6:nth-child(odd) {
      clear: left;
    }
  }
}

.auto-clear {
  @include media-breakpoint-up(xl) {
    @include row-first-child(lg);
  }
  @include media-breakpoint-up(lg) {
    @include row-first-child(md);
  }
  @include media-breakpoint-up(md) {
    @include row-first-child(sm);
  }
  @include media-breakpoint-up(sm) {
    @include row-first-child(xs);
  }
}
